import { createStore } from "vuex";

export default createStore({
  state: {
    contentVisible: false
  },
  getters: {
    contentVisibility: state => state.contentVisible
  },
  mutations: {
    SET_CONTENT_VISIBILITY(state, isVisible) {
      state.contentVisible = isVisible;
    }
  },
  actions: {
    changeContentVisibility({ commit }, isVisible) {
      commit('SET_CONTENT_VISIBILITY', isVisible);
    }
  },
  modules: {},
});
