import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import "element-plus/theme-chalk/index.css"
import "element-plus/theme-chalk/dark/css-vars.css"
// import './styles/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }

app.use(ElementPlus).use(store).use(router).mount("#app");
