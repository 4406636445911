<template>
  <div class="h_content">
    <div class="logo">
      <img src="../assets/home_logo.png" />
    </div>
    <div class="tab_cont">
      <div
        @click="navGo(index, item.path)"
        :class="active == index ? 'active' : ''"
        class="tab_li btn"
        v-for="(item, index) in list"
        :key="index"
      >
        {{ item.name }}
      </div>

      <div v-if="userInfoAll">
        <div
          v-if="userInfoAll.authority !== 9"
          class="tab_li activea"
          @click="onPushHome(4)"
        >
          控制台
        </div>
        <div v-if="userInfoAll.authority == 9" class="tab_li" @click="pupShow()">
          管理后台
        </div>
      </div>

      <div v-else class="tab_li activea" @click="onPushHome(4)">控制台</div>
    </div>

    <div class="right_box">
      <!-- <template> -->
      <!-- <div v-if="userinfo">
        <div
          v-if="userinfo.authority !== 9"
          class="tab_li active"
          @click="onPushHome(4)"
        >
          控制台
        </div>
        <div
          v-if="userinfo.authority == 9"
          class="tab_li "
          @click="pupShow()"
        >
          管理后台
        </div>
      </div> -->

      <!-- </template> -->
      <!-- <div v-else class="tab_li active" @click="onPushHome(4)">控制台</div> -->
      <!-- </template> -->
      <!-- <div  class="tab_li active" @click="onPushHome(4)">控制台</div> -->
      <div class="login">
        <div class="txt" v-if="userInfoAll">
          {{ userInfoAll.userName }}
          <!-- <div class="user_img">
            <img src="../assets/Avatar.png" />
          </div> -->
          <div class="none_bg">
            <div class="none_icon" @click="removeAll()">
              <img src="../assets/exit.png" />
              <div class="name">退出登录</div>
            </div>
          </div>
        </div>
        <div class="txt" v-else @click="goLogin()">Login</div>
      </div>
    </div>
  </div>

  <div class="popup" v-if="false">
    <!-- <div class=""> -->

    <div class="data_cont">
      <el-input
        v-model="ruleForm.UserName"
        placeholder="请输入用户名称"
        class="input_st"
      >
        <!-- <template #prefix>
              <div class="data__icon">
                <img src="../assets/user.png" />
              </div>
            </template> -->
      </el-input>
      <div class="btn_st">
        <div class="tip_cont" @click="submitF">激活</div>
        <div class="tip_cont" @click="pupShow">关闭弹窗</div>

        <!-- ? -->
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>


<script lang="ts" setup>
import { TempSetUserAuthority } from "@/api/api";
import type { FormInstance, FormRules } from "element-plus";
import router from "@/router";
import { useRoute, useRouter } from "vue-router";
import { el, es } from "element-plus/es/locale";
import { ElMessage } from "element-plus";
import { onMounted, ref, reactive, watch } from "vue";
const ruleForm = reactive({
  UserName: "",
});
const list = ref([
  {
    name: "首页",
    path: "/home",
  },
  {
    name: "推广返佣",
    path: "/userInfo/Invite",
  },
  {
    name: "商城",
    path: "/userInfo/goods",
  },
  // {
  //   name: "使用文档",
  //   path:'/userInfo/goods'
  // },
  {
    name: "钱包",
    path: "/userInfo/user",
  },
  {
    name: "个人中心",
    path: "/userInfo/security",
  },
]);
const active = ref(0);
interface User {
  userName: string;
}

const tipName = ref({
  name: "Login",
});

onMounted(() => {
 
  let url  = useRoute().path
  const newIndex = list.value.findIndex((item) => item.path === url);
  active.value = newIndex;
  // });
  
});

// function gitInfo(){

// }
const popupShow = ref(false);
const userInfoAll = ref(null);
const userInfoString = localStorage.getItem("userinfo");

console.log("userInfoString", userInfoString);
var userinfo: User | null = userInfoString
  ? (JSON.parse(userInfoString) as User)
  : null;

if (userinfo) {
  tipName.value.name = userinfo.userName;
  userInfoAll.value = userinfo;
}

function pupShow() {
  popupShow.value = !popupShow.value;
}

// const active = ref(null);

// watch(
//   () => useRoute().path,
//   (newPath) => {
//     const route = useRoute();
//     active.value = route.meta.activeTab;
//   }
// );
function navGo(n: number, path: string) {
   console.log("userinfo", userInfoString);
  if(userInfoString==null&&n!=0&&n!=2){
    // active.value = n;
    // router.push(path);
    ElMessage.error("未登录");
  }else{
    active.value = n;
    router.push(path);
  }
 
  return
  if(userinfo.authority==8){
    router.push(path);
    active.value = n;
  }else if(n==0){
    router.push(path);
    active.value = n;
  }else{
    ElMessage.error("暂未开放");

  }
}

function submitF() {
  let data = {
    UserName: ruleForm.UserName,
  };
  TempSetUserAuthority(data).then((res: any) => {
    if (res.code == 0) {
      ElMessage({
        message: "激活成功",
        type: "success",
      });
      popupShow.value = false;
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
}
function removeAll() {
  // const routeurl = useRoute()
  // console.log(routeurl)

  // return
  // window.location.reload();
  // active.value = 0
  if(active.value==0|| active.value==2){
    // router.push("/login");
  }else{
    router.push("/login");
  }
  
  userinfo = null;
  userInfoAll.value = null
  localStorage.removeItem("userinfo");
  ElMessage({
    message: "退出成功",
    type: "success",
  });

  localStorage.removeItem("appToken");
}
const onPushHome = (e: any) => {
  let token = localStorage.getItem("appToken");
  console.log(token);

  // return
  if (e == 4) {
    if (token === "" || token === undefined || token === null) {
      ElMessage({
        message: "请先登录",
        type: "error",
      });
    } else {
      // location.href = "/project2/index.html#Biz/Square/Index";
      // location.href = '/apps/home/index.html#Biz/Square/Index';
      // window.open('/project2/index.html#/Biz/Setting/Index', '_blank');
      window.open('/apps/home/index.html#Biz/Square/Index', '_blank');
    }
  }

  return;
};
function goLogin() {
  // if (userinfo) {
  // } else {

    // console.log("點的什麽")
    router.push("/login");
  // }
}
</script>


<style lang="scss" scoped>
.user_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
img {
  width: 100%;
  height: 100%;
}
.h_content {
  // width: 100vw;
  display: flex;
  // align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 16px 53px;
  box-sizing: border-box;
  min-width: 1200px;
}
.logo {
  width: 134px;
  img {
    width: 100%;
  }
}
.login {
  cursor: pointer;
  .txt {
    font-size: 14px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 14px 26px;
    border-radius: 40px;
    position: relative;

    .none_bg {
      display: none;
      // bottom: -100%;
      right: 0;
      position: absolute;
      // min-width: 100px;
      min-width: 130px;
      padding-top: 30px;
    }
    .none_icon {
      background: rgba(255, 255, 255, 0.08);
      border-radius: 10px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      // margin-right: ;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .name {
        font-size: 14px;
      }
    }
  }
  .txt:hover .none_bg {
    display: block;
  }
}
.tab_cont {
  display: flex;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.08);
  padding: 16px;
  // border-radius: 40px;
}
.tab_li {
  font-size: 18px;
  color: #fff;
  padding: 10px 24px;
  box-sizing: border-box;
  // border-radius: 20px;
}
.active {
  // background: linear-gradient(135deg, #9dff7b 0%, #ade681 100%);
  border-bottom: 1px solid #9dff7b;
  // border-radius: 12px 12px 12px 12px;
  font-weight: normal;
  cursor: pointer;
  color: #9dff7b;
}
.activea {
  background: linear-gradient(135deg, #9dff7b 0%, #ade681 100%);
  // border-bottom: 1px solid #9dff7b;
  border-radius: 12px 12px 12px 12px;
  font-weight: normal;
  cursor: pointer;
  color: #000;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_st {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .tip_cont {
    margin-top: 20px;
    width: 100%;
    padding: 6px 20px;
    font-size: 18px;
    background-color: #9dff7b;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
  }
}
::v-deep .el-input__inner {
  padding: 20px 0;
}
</style>