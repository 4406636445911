// import http from '../utils/requerts/request'
import http from "@/utlis/requerts/request"

//get有值
export function LoginPost(data: any) {
  return http.request({
    url: '/Home/Login',
    method: 'post',
    data:data
  })
}
export function Register(data: any) {
    return http.request({
      url: '/Home/Register',
      method: 'post',
      data:data
    })
  }

  //获取手机验证码
  export function postPCode(data: any) {
    return http.request({
      url: '/Home/RegisterSendPhoneVerificationCode',
      method: 'post',
      data:data
    })
  }
  export function postECode(data: any) {
    return http.request({
      url: '/Home/RegisterSendEmailVerificationCode',
      method: 'post',
      data:data
    })
  }

//激活
  export function TempSetUserAuthority(params: any) {
    return http.request({
      url: '/Home/TempSetUserAuthority/'+params.UserName,
      method: 'get',
      // params
    })
  }
 

//检查用户名是否可以注册
  export function CheckUserName(data: any) {
    return http.request({
      url: '/Home/CheckUserName',
      method: 'post',
      data:data
    })
  }


  //检查邮箱是否可以注册
  export function CheckEmail(data: any) {
    return http.request({
      url: '/Home/CheckEmail',
      method: 'post',
      data:data
    })
  }

   //检查手机号是否可以注册
   export function CheckPhoneNumber(data: any) {
    return http.request({
      url: '/Home/CheckPhoneNumber',
      method: 'post',
      data:data
    })
  }

  
// 测试token接口
//   export function TestAuth() {
//     return http.request({
//       url: '/TestAuth',
//       method: 'post',
//     //   data:data
//     })
//   }

//获取用户信息
export function UserInfo(params: any) {
  return http.request({
    url: '/Home/UserInfo',
    method: 'get',
    params
  })
}
// 获取邀请码
export function GetInvitatCode(params: any) {
  return http.request({
    url: '/Home/GetInvitatCode',
    method: 'get',
    params
  })
}



  export function TestAuth(params: any) {
    return http.request({
      url: '/TestAuth',
      method: 'get',
      params
    })
  }
  

  // 登录历史
  export function LoginLogs(params: any) {
    return http.request({
      url: '/Account/LoginLogs',
      method: 'get',
      params
    })
  }
  
// 获取钱包地址
  export function Wallets(params: any) {
    return http.request({
      url: '/Account/Wallets',
      method: 'get',
      params
    })
  }

  // 优惠券
  export function AllCoupons(params: any) {
    return http.request({
      url: '/Biz/AllCoupons',
      method: 'get',
      params
    })
  }

  //要查询的类型，-1为全部，1为充值记录，2为消费记录，3为提现记录，4为返佣记录
  export function Funds(params: any) {
    return http.request({
      url: '/Account/Funds',
      method: 'get',
      params
    })
  }
  
  // 添加自己的收款地址
  export function AddWallet(data:any) {
    return http.request({
      url: '/Account/AddWallet',
      method: 'post',
      data:data
    })
  }

  //编辑钱包地址
  export function EditWallet(data:any) {
    return http.request({
      url: '/Account/EditWallet',
      method: 'post',
      data:data
    })
  }


  //删除钱包地址
  export function DeleteWallet(data:any) {
    return http.request({
      url: '/Account/DeleteWallet',
      method: 'post',
      data
    })
  }


  //提现
  export function Withdrawal(data:any) {
    return http.request({
      url: '/Account/Withdrawal',
      method: 'post',
      data:data
    })
  }

  //查询可用优惠券（用于购买商品时选择）
  /**
   * 获取可用优惠券
   * @param params 
   * @returns 
   */
  export function AvailableCoupons(params: any) {
    return http.request({
      url: '/Biz/AvailableCoupons',
      method: 'get',
      params
    })
  }

  //发起充值
  export function Recharge(data:any) {
    return http.request({
      url: '/Biz/Recharge',
      method: 'post',
    })
  }

  //充值状态获取
  export function OrderState(params: any) {
    return http.request({
      url: '/Biz/OrderState',
      method: 'get',
      params
    })
  }

  //充值成功测试
  export function TestRechargeSuccess(params: any) {
    return http.request({
      url: '/Biz/TestRechargeSuccess',
      method: 'get',
      params
    })
  }

  //获取商品
  export function Goods(params: any) {
    return http.request({
      url: '/Biz/Goods',
      method: 'get',
      params
    })
  }


  //下单
  export function Buy(data:any){
    return http.request({
      url: '/Biz/Buy',
      method: 'post',
      data:data
    })
  }

  // 获取购买记录 
  export function Businesses(params: any) {
    return http.request({
      url: '/Account/Businesses',
      method: 'get',
      params
    })
  }
  //修改续费状态
export function SetAutoRenewal(data:any){
    return http.request({
      url: '/Biz/SetAutoRenewal',
      method: 'post',
      data:data
    })
  }


  // /Account/UserInfo
  /**
   * 获取用户信息
   * @param params 
   * @returns 
   */
export function UserItem(params: any) {
    return http.request({
      url: '/Account/UserInfo',
      method: 'get',
      params
    })
  }
  
  
  /**
   * 获取佣金返利规则
   * @param params 
   * @returns 
   */
export function CommissionRebateRules(params: any) {
    return http.request({
      url: '/Biz/CommissionRebateRules',
      method: 'get',
      params
    })
  }

  /**
   * 
   * 获取邀请人数优惠券
   * @param params 
   * @returns 
   */
export function DistributeCouponRules(params: any) {
    return http.request({
      url: '/Biz/DistributeCouponRules',
      method: 'get',
      params
    })
  }



  /**
   * 
   * 
   * 获取优惠券规则
   * @param params 
   * @returns 
   */
export function CouponRules(params: any) {
    return http.request({
      url: '/Biz/CouponRules',
      method: 'get',
      params
    })
  }

  
  /**
   * 优惠券规则all
   * @param params 
   * @returns 
   */
  export function Rules(params: any) {
    return http.request({
      url: '/Biz/Rules',
      method: 'get',
      params
    })
  }
  
  /**
   * 
   * 获取交易所
   * @param params 
   * @returns 
   */
export function ExchangeInfos(params: any) {
    return http.request({
      url: '/Exchange/Infos',
      method: 'get',
      params
    })
  }

  /**
   * 根据交易所查询邀请人折扣
   * @param params 
   * @returns 
   */
export function InviteeDiscount(params: any) {
    return http.request({
      url: '/Biz/InviteeDiscount',
      method: 'get',
      params
    })
  }


  /**
   * 验证受邀关系
   * @param params 
   * @returns 
   */
export function CheckInvited(data: any) {
    return http.request({
      url: '/Biz/CheckInvited',
      method: 'post',
      data:data
    })
  }

  /**
   * 领取七天试用
   * @param data 
   * @returns 
   */
  export function Trial(data:any) {
    return http.request({
      url: '/Biz/Trial',
      method: 'post',
      data:data
    })
  }

  /**
   * 修改密码
   * @param data 
   * @returns 
   */
  export function ResetPassword(data:any) {
    return http.request({
      url: '/Home/ResetPassword',
      method: 'post',
      data:data
    })
  }


  
  /**
   * 验证uid
   * @param data 
   * @returns 
   */
  export function CheckBinding(data:any) {
    return http.request({
      url: '/Biz/CheckBinding',
      method: 'post',
      data:data
    })
  }


 
  /**
   * 
   * 发送验证码
   * @param data
   * @returns
   * */
  export function WithdrawalSendVerificationCode(data:any) {
    return http.request({
      url: '/Account/WithdrawalSendVerificationCode',
      method: 'post',
      data:data
    })
  }

  
  /**
   * 获取用户协议
   * @param data 
   * @returns 
   */
  export function Agreement(params:any) {
    return http.request({
      url: '/Docs/agreement.md',
      method: 'get',
      params
    })
  }

  
  /**
   * 取消订单
   * @param data 
   * @returns 
   */
  export function CancelOrder(data:any) {
    return http.request({
      url: '/Biz/CancelOrder',
      method: 'post',
      data:data
    })
  }


  /**
   * 获取未完成订单
   * @param data
   * @returns
   * */
  export function UnfinishedOrder(params:any) {
    return http.request({
      url: '/Biz/UnfinishedOrder',
      method: 'get',
      params
    })
  }


  /**
   * 接受协议
   * @param data
   * @returns
   * */
  export function AcceptAgreement(data:any) {
    return http.request({
      url: '/Account/AcceptAgreement',
      method: 'post',
      data:data
    })
  }

// //get无值
// export function (params: any) {
//   return http.request({
//     url: '/test',
//     method: 'get',
//     params
//   })
// }
