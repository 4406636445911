<template>
  
  <router-view />
</template>

<script lang="ts" setup>
import { watchEffect, onMounted,ref } from 'vue';  
import { useRoute } from 'vue-router';  
  
const route = useRoute();  
watchEffect(() => {  
  // 检查当前路由的 meta 字段中是否有 title  
  if (route.meta && (route.meta.title as string)) {  
    document.title = route.meta.title as string;  
  }  
  document.documentElement.classList.add('dark');
 
}); 


// 设置其他暗黑模式下的 CSS 变量
</script>

<style lang="scss">
#app {

}
body{
  margin: 0;
  min-width: 1200px;
  // width: 100%;
}
.st_flex{
  display:flex;
  align-items:center !important;
}
.btn{
  cursor: pointer;
}
.empty_box{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ccc;
  margin-top: 40px;
}

.del_box {
  .del_title {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 56px;
  }
  .btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      width: 47%;
      // height: 66px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      box-sizing: border-box;
    }
    .btn_verify {
      background-color: #9dff7b;
      color: #000;
    }
    .btn_verify_1{
      margin: auto;
    }
    .btn_cancel {
      color: #fff;
      border: 1px solid #fff;
    }
  }
}

</style>
